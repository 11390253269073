<template lang="pug">
.step-field-mapping
  loading-logo(v-if="loading")
  template(v-else)
    .title {{ $t('integrationFlow.integrationModal.general.fieldMappingAltTitle', { type: 'HubSpot' }) }}
    .d-flex.fields-wrapper.w-100
      .d-flex.fields-title
        .om-fields-header {{ $t('integrationFlow.integrationModal.general.omInputFields') }}
        .arrow-block
        .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'HubSpot' }) }}
      .d-flex.fields(v-for="(binding, index) in formBindings")
        binding-row.w-100.justify-content-between(
          :isNewFlow="true"
          :binding="binding"
          :index="index"
          :fields="fields"
          :type="settings.type"
          :hasError="bindingRowHasError(binding)"
        )
    .binding-error-message(v-if="missingRequiredFields.length") {{ bindingErrorMessage }}
    .d-flex.additional-data
      .d-flex.additional-data-row
        label.d-flex(for="additionalDataCheckbox")
          input#additionalDataCheckbox(type="checkbox" v-model="checkboxValue")
          .additional-data-text {{ $t('integrationFlow.integrationModal.general.additionalData') }}
      .d-flex.additional-data-row
        .additional-data-helper {{ $t('integrationFlow.integrationModal.general.additionalDataHelper') }}
    transition(name="fade")
      .d-flex.other-fields(v-if="checkboxValue")
        .d-flex.fields-wrapper.w-100
          .d-flex.fields-title
            .om-fields-header {{ $t('integrationFlow.integrationModal.general.customValue') }}
            .arrow-block
            .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'HubSpot' }) }}
          .d-flex.fields(v-for="(binding, index) in campaignDataBindings")
            .om-campaign-additional-fields.w-100
              binding-row.w-100.justify-content-between(
                :isNewFlow="true"
                :binding="binding"
                :index="index"
                :fields="fields"
                :type="settings.type"
              )
          .d-flex.fields(
            v-if="staticBindings.length > 0"
            v-for="(binding, index) in staticBindings"
          )
            .om-campaign-static-fields.w-100
              binding-row.w-100.justify-content-between(
                :isNewFlow="true"
                :binding="binding"
                :index="index"
                :fields="fields"
                :type="settings.type"
                @removeStaticRow="deleteStaticField(index)"
              )
          .add-fields
            .d-flex.cursor-pointer(@click="addStaticField")
              om-button(icon="plusCircle" iconSize="1.715em" iconOnly style="color: #ed5a29")
              .font-size-0--875.font-weight-semibold.line-height-1.align-self-center {{ $t('integrationFlow.integrationModal.general.addCustomValue') }}
</template>
<script>
  import BindingRow from '@/components/IntegrationBindings/Row.vue';
  import { UilArrowRight } from '@iconscout/vue-unicons';
  import bindingMixin from '@/mixins/integration/binding';
  import { HubSpotIntegrationService } from '@/services/integrations/hubSpotIntegrationService';

  export default {
    components: {
      UilArrowRight,
      BindingRow,
    },
    mixins: [bindingMixin],
    data() {
      return {
        fields: [],
        loading: true,
      };
    },
    async mounted() {
      if (this.settings.fields) {
        this.fields = this.settings.fields;
        this.loading = false;
        return;
      }

      const hubSpotIntegrationService = new HubSpotIntegrationService();
      const { fields } = await hubSpotIntegrationService.fetchListAndFields(
        this.$apollo,
        this.settings,
      );

      this.fields = fields;
      this.loading = false;
    },
  };
</script>
